const API = process.env.INFERNO_APP_API_HOST+':'+process.env.INFERNO_APP_API_PORT;

function getCharList() {
  return fetch(`${API}/chars`)
    .then(_verifyResponse, _handleError);
}

function getCharInfoAndMoves(char) {
  return fetch(`${API}/chars/${char}`)
    .then(_verifyResponse, _handleError);
}

function getMove(character, move) {
  return fetch(`${API}/chars/${character}/moves/${move}`)
    .then(_verifyResponse, _handleError);
}

function submitError(character, move, message) {
  // post body data
  const data = {
      char: character,
      move: move,
      message: message
  };

    // request options
  const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
          'Content-Type': 'application/json'
      }
  }

  return fetch(`${API}/data/error`, options)
    .then(_verifyResponse, _handleError);
}

function _verifyResponse(res) {
  let contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    if (res.ok) return res.json(); else _handleError(res.statusText);
  } else {
    _handleError({ message: 'Response was not JSON'});
  }

}

function _handleError(error) {
  console.error('An error occurred:', error);
  throw error;
}

export const ApiService = { getCharList, getCharInfoAndMoves, getMove, submitError };
