import { Link } from 'inferno-router';
import { Component } from 'inferno';
import { BrowserRouter, Route } from 'inferno-router';
import { Home } from './Home.js';
import { MoveSearch } from './MoveSearch.js';
import { CharacterOverview } from './CharacterOverview.js';
import { CharacterList } from './CharacterList.js';
import { MoveAnimationDisplay } from './MoveAnimationDisplay.js';
import { Row, Col } from 'inferno-bootstrap';
import './App.css';
import './css/bootstrap.min.css';

const About = () => (
  <div>
    <h2>About</h2>
  </div>
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <header className="App-header">
            <h1>USF4 Frame Viewer</h1>
            <Row style={{width: "100%"}}>
              <Col xs="auto">
                <Link to="/char">Character Info</Link>
              </Col>
              { (!this.isProd()) ?
              <Col xs="auto">
                <Link to="/search">Move Search</Link>
              </Col>
              : ""}
              <Col>
                <p className="App-version-display">v{this.getAppVersion()}</p>
              </Col>
            </Row>
          </header>
          <div className="App-content">
            <p>
                <Route exact path="/char/:charName/:moveId" component={MoveAnimationDisplay}/>
                <Route exact path="/char/:charName" component={CharacterOverview} />
                <Route exact path="/char" component={CharacterList}/>
                <Route exact path="/about" component={About}/>
                <Route exact path="/search" component={MoveSearch}/>
                <Route exact path="/" component={Home}/>
            </p>
          </div>
        </BrowserRouter>
      </div>
    );
  }

  getAppVersion() {
    if (process.env.INFERNO_APP_PROD_VERSION)
      return process.env.INFERNO_APP_PROD_VERSION;
    if (process.env.INFERNO_APP_RC_VERSION)
      return this.incrementVersion(process.env.INFERNO_APP_RC_VERSION) + "-rc";
    else
      return this.incrementVersion(process.env.INFERNO_APP_DEV_VERSION) + "-" + process.env.INFERNO_APP_TYPE_VERSION + "+" + process.env.INFERNO_APP_GIT_REV;
  }

  isProd() {
    return process.env.NODE_ENV === 'production';
  }

  incrementVersion(oldVersion) {
    var versionArray = oldVersion.split(".");
    versionArray.push(parseInt(versionArray.pop()) + 1);
    return versionArray.join(".");
  }
}

export default App;
