import { Component } from 'inferno';
import { ButtonToolbar, ButtonGroup, Button, Row, Col } from 'inferno-bootstrap';
import { Card, CardBody, CardTitle, CardText } from 'inferno-bootstrap';
import { SuperGif } from './libgif.js';
import { SuperGifContainer } from './SuperGifContainer.js';
import { ApiService } from './ApiService.js';
import { MoveReportButton } from './MoveReportButton.js';
import startup_button from './buttons/startup.gif';
import active_button from './buttons/active.gif';
import recovery_button from './buttons/recovery.gif';
import { adv } from './util.js';


const AnimationType= {
  BASE: 'base',
  HITBOX: 'hitbox'
}

function mapFrameType(currentValue, index, arr) {
  return {
    frame_number: index,
    frame_type: currentValue.frame_type
  }
}

function start(frame_count) {
  var frame_array = []
  for (var i = 0; i < frame_count - 1; i++)
  {
    frame_array.push({"frame_type": "startup"});
  }
  return frame_array;
}

function active(frame_count) {
  var frame_array = []
  for (var i = 0; i < frame_count; i++)
  {
    frame_array.push({"frame_type": "active"});
  }
  return frame_array;
}

function recovery(frame_count) {
  var frame_array = []
  for (var i = 0; i < frame_count; i++)
  {
    frame_array.push({"frame_type": "recovery"});
  }
  return frame_array;
}

function getFrameArray() {
  return [].concat(...arguments).map(mapFrameType);
}

class FrameDisplay extends Component {
  getFrameImage(frame_type) {
    var img;
    switch(frame_type) {
      case "startup":
        img = startup_button;
        break;
      case "active":
        img = active_button;
        break;
      case "recovery":
        img = recovery_button;
        break;
      default:
        // code block
    }
    return img;
  }

  getFrameButtonColour(frame_type) {
    var img;
    switch(frame_type) {
      case "startup":
        img = "warning";
        break;
      case "active":
        img = "danger";
        break;
      case "recovery":
        img = "primary";
        break;
      default:
        // code block
    }
    return img;
  }

  render(props, state) {
    return (
      <Row>
        { props.frames ? (
            <Col className="flex-wrap">
               { props.frames.map((frame) => (
                  <Button className="p-2 rounded-0" onClick={() => props.onFrameSelected(frame.frame_number)} color={this.getFrameButtonColour(frame.frame_type)} >
                    {frame.frame_number + 1}
                  </Button> )) }
            </Col>
          ) : ( <p>Loading...</p> )
        }
      </Row>
    );
  }
}

class PlaybackControls extends Component {
  render(props, state) {
    return (
        <div>
          <ButtonToolbar className="justify-content-center">
            <ButtonGroup className="mr-2">
              <Button disabled={!props.enabled} onClick={props.onPlay}> Play </Button>
              <Button disabled={!props.enabled} onClick={props.onPause}> Pause </Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2">
              <Button disabled={!props.enabled} onClick={props.onPrevFrame}> Prev frame </Button>
              <Button disabled={!props.enabled} onClick={props.onNextFrame}> Next frame </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button disabled={!props.enabled} onClick={props.onToggleBoxes}> Toggle hitboxes </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
    );
  }
}

export class MoveAnimationDisplay extends Component {
  constructor (props) {
    super(props);
    this.loadGifs = this.loadGifs.bind(this);
    this.doneLoading = this.doneLoading.bind(this);
    this.playAnimations = this.playAnimations.bind(this);
    this.pauseAnimations = this.pauseAnimations.bind(this);
    this.goToRelativeFrame = this.goToRelativeFrame.bind(this);
    this.goToPreviousFrame = this.goToPreviousFrame.bind(this);
    this.goToNextFrame = this.goToNextFrame.bind(this);
    this.goToFrame = this.goToFrame.bind(this);
    this.toggleBoxes = this.toggleBoxes.bind(this);
    this.state = {base_gif: null, hitbox_gif: null, char: null, move: null, current_frame: 0, error: null};
  }

  componentDidMount() {
    ApiService.getMove(this.props.match.params.charName, this.props.match.params.moveId)
      .then(
        res => {
          this.setState({
            move: res,
            char: this.props.match.params.charName,
            frames: getFrameArray(start(res.startup), active(res.active), recovery(res.recovery))
          });

          console.log(res)
        },

        error => {
          this.setState({
            error: error
          });
        }
    );
  }

  pauseAnimations() {
    this.state.base_gif.pause();
    this.state.hitbox_gif.pause();
  }

  playAnimations() {
    this.state.base_gif.play();
    this.state.hitbox_gif.play();
  }

  goToPreviousFrame() {
    this.goToRelativeFrame(-1);
  }
  goToNextFrame() {
    this.goToRelativeFrame(1);
  }

  goToRelativeFrame(frame) {
    this.state.base_gif.move_relative(frame);
    this.state.hitbox_gif.move_relative(frame);
    this.setState({current_frame: this.state.current_frame+frame});
  }

  toggleBoxes() {
    this.state.hitbox_gif.get_canvas().style.visibility = (this.state.hitbox_gif.get_canvas().style.visibility === 'hidden' ? 'visible' : 'hidden');
  }

  loadGifs() {
    var base_gif = new SuperGif({ gif: document.getElementById('base_gif'), show_gif_while_loading: false, draw_while_loading: true, show_progress_bar: true } );
    base_gif.load(this.doneLoading.bind(this, AnimationType.BASE));
    var hitbox_gif = new SuperGif({ gif: document.getElementById('hitbox_gif'), show_gif_while_loading: true, draw_while_loading: false, show_progress_bar: false } );
    hitbox_gif.load(this.doneLoading.bind(this, AnimationType.HITBOX));

    this.setState({base_gif: base_gif, hitbox_gif: hitbox_gif,
                   base_gif_loaded: false, hitbox_gif_loaded: false,
                   gif_width: null, gif_height: null});
  }

  doneLoading(gif_type, img, hdr) {
    switch (gif_type) {
      case AnimationType.BASE:
        this.setState({base_gif_loaded: true, gif_width: hdr.width, gif_height: hdr.height});
        break;
      case AnimationType.HITBOX:
        this.setState({hitbox_gif_loaded: true});
        break;
      default:
        break;
    }
  }

  goToFrame(frame_number) {
    if (this.state.base_gif_loaded && this.state.hitbox_gif_loaded) {
      this.pauseAnimations()
      this.state.base_gif.move_to(frame_number + 20);
      this.state.hitbox_gif.move_to(frame_number + 20);
    }
    this.setState({current_frame: frame_number});

  }

  render() {
    if (this.state.error != null) {
      return `THERE WAS AN ERROR GETTING THAT MOVE: ${this.state.error}`;
    }

    if (this.state.move == null) {
      return "loading";
    }

    return (
      <div style={{position: 'relative'}}>
        <Row className="justify-content-end">
          <MoveReportButton char={this.state.char} move={this.state.move.fullName} buttonLabel="Report an error with this move" />
        </Row>
        <h1>{this.state.move.fullName}</h1>

        <h2>Startup: {this.state.move.startup},
        Active: {this.state.move.active},
        Recovery: {this.state.move.recovery}</h2>

        <Row>
          <Col xs="7">
            <SuperGifContainer componentDidMount={this.loadGifs}
                               width={this.state.gif_width} height={this.state.gif_height}
                               base_gif={`moves/${this.props.match.params.charName}/${this.props.match.params.moveId}.gif`}
                               hitbox_gif={`moves/${this.props.match.params.charName}/${this.props.match.params.moveId}.hit.gif`} />


            <PlaybackControls enabled={this.state.base_gif_loaded && this.state.hitbox_gif_loaded}
                              onPlay={this.playAnimations} onPause={this.pauseAnimations}
                              onPrevFrame={this.goToPreviousFrame}
                              onNextFrame={this.goToNextFrame}
                              onToggleBoxes={this.toggleBoxes}
                              />
          </Col>
          <Col xs="5">
            <Card>
              <CardBody>
                <CardTitle>Move Properties</CardTitle>
                <CardText>
                  <Row>
                    <Col>Damage: {this.state.move.Damage}</Col>
                    <Col>Stun: {this.state.move.Stun}</Col>
                  </Row>
                  <Row>
                    <Col>Hit/Block Advantage: {adv(this.state.move.AdvOnHit)}/{adv(this.state.move.AdvOnGuard)}</Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                  <CardTitle>Frame {this.state.current_frame + 1} Properties</CardTitle>
                  <CardText>Type: {this.state.frames[this.state.current_frame].frame_type}</CardText>
                  <CardText>damage (if it's an active frame)</CardText>
                  <CardText>invulnerability: whatever</CardText>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <FrameDisplay frames={this.state.frames} onFrameSelected={this.goToFrame} move={this.state.move} />
      </div>
    );
  }
}

export default MoveAnimationDisplay;
