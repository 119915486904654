import { Component } from 'inferno';
import { Button } from 'inferno-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'inferno-bootstrap';
import { Form, Input } from 'inferno-bootstrap';
import { ApiService } from './ApiService.js';


export class MoveReportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      char: props.char,
      move: props.move
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    ApiService.submitError(this.state.char, this.state.move, this.state.description).then(
      res => {
        this.toggle();
      },
      error => {
        this.toggle();
      }
    );
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }



  render(props, state) {
    const fadeModal = (props.hasOwnProperty('fade') ? this.props.fade : true)
    return (
      <Button onClick={this.toggle}>{this.props.buttonLabel}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={fadeModal}>
          <Form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={this.toggle}>Report Move Error</ModalHeader>
            <ModalBody>
              Fak u and describe the issue below
              <Input type="text" name="description" onInput={this.handleChange} />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">Submit</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Button>
    );
  }
}
