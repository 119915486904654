import { Component } from 'inferno';
import loading_button from './loading_small.gif';


export class SuperGifContainer extends Component {
  render(props, state) {
    return (
        <div class="gif-container" style={{
          height: (props.height ? props.height : 64) + 'px',
          width:  (props.width ? props.width : 64) + 'px'
        }}>
          <img id="base_gif" src={ loading_button }
           rel:animated_src={process.env.PUBLIC_URL + '/' + props.base_gif}
           rel:auto_play="0" draw_while_loading="false" alt="" />
          <img id="hitbox_gif" src={ loading_button }
           rel:animated_src={process.env.PUBLIC_URL + '/' + props.hitbox_gif}
           rel:auto_play="0" alt="" />
        </div>
    );
  }

  componentDidMount() {
    this.props.componentDidMount();
  }
}
