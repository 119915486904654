import { Component } from 'inferno';
import { Link } from 'inferno-router';
import { ApiService } from './ApiService.js';
import { Card, CardBody } from 'inferno-bootstrap'
import { Row } from 'inferno-bootstrap'

export class CharacterList extends Component {
    constructor(props) {
      super(props)
      this.state = {
        characters: []
      }
    }

    componentDidMount() {
        ApiService.getCharList()
          .then(
            res => {
              this.setState({
                characters: res
              });
            },

            error => {
              this.setState({
                error: error
              });
            }
        );
    }

    render({ match }) {
        if (this.state == null || !Array.isArray(this.state.characters)) {
            return "Unable to get character list";
        }

        return(
          <div>
              <h3>Please select a character.</h3>
              <Row style={{width: "1100px"}}>
                  {this.state.characters.map((c) =>
                      <Link to={`${match.url}/${c[0]}`}>
                        <img src={process.env.PUBLIC_URL + "char_icons/" + c[0] + ".gif"}
                             alt={c[0]} />
                      </Link>
                  )}
              </Row>

              <Card>
                <CardBody>
                  <ul style={{columns: 3}}>
                      {[...this.state.characters].sort().map((c) =>
                          <li><Link to={`${match.url}/${c[0]}`}>{c[1]}</Link></li>
                      )}
                  </ul>
                </CardBody>
              </Card>
          </div>
        )
    };
}
