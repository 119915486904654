import { Component } from 'inferno';
import { ApiService } from './ApiService.js';
import { Card, CardHeader, CardBody, CardText, Button } from 'inferno-bootstrap'
import { Container, Row, Col } from 'inferno-bootstrap'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'inferno-bootstrap'
import { adv } from './util.js';

const categoryTypes = {
  "normal": "Normals",
  "jump": "Jumping Normals",
  "command": "Command Normals",
  "target": "Target Combos",
  "special": "Special Moves",
  "focus": "Focus Attacks",
  "throw": "Universal Throws",
  "super": "Super Combos",
  "ultra": "Ultra Combos",
  "other": "Other"
}

const orderedTypes = [
  "normal",
  "jump",
  "command",
  "target",
  "special",
  "focus",
  "throw",
  "super",
  "ultra",
  "other"
]

function getMoveTypeName(moveType) {
  return moveType ? categoryTypes[moveType] : "Other";
}


export class CharacterOverview extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  processMoves(moves) {
    return moves.reduce((accumulator, currentValue) => {
      const moveType = currentValue.moveType;
      if (!(moveType in accumulator)) {accumulator[moveType] = []}
      accumulator[moveType].push(currentValue);
      return accumulator;
    }, {})
  }

  componentDidMount() {
      ApiService.getCharInfoAndMoves(this.props.match.params.charName)
        .then(
          res => {
            this.setState({
              info: res["info"],
              charName: res["charName"],
              moves: this.processMoves(res["moves"])
            });
          },

          error => {
            this.setState({
              error: error
            });
          }
      );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render({ match }) {
    return (
      <div>
        <h2>{ this.state.charName }</h2>
        <Row>
          <Col xs="8">
            <Card>
              <CardHeader>Character Info</CardHeader>
              <CardBody>
                {(this.state.info) ? (
                  <CardText>
                  <Row id="character-info">
                    <Col xs="5">
                      <Row><Col>Health/Stun</Col></Row>
                      <Row><Col>W Ultra Scaling</Col></Row>
                      <Row><Col>Walk Speed</Col></Row>
                      <Row>
                        <Col xs="auto">Dash</Col>
                        <Col>
                          <Row><Col xs="auto">Forwards</Col></Row>
                          <Row><Col xs="auto">Back</Col></Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="auto">Jump</Col>
                        <Col xs="auto">
                          <Row>Height</Row>
                          <Row>Duration</Row>
                          <Row>Distance</Row>
                        </Col>
                      </Row>
                      <Row><Col>Wake Up Timing</Col></Row>
                      <Row><Col>FA Dash (forwards/back):</Col></Row>
                    </Col>
                    <Col xs="7">
                      <Row><Col>{this.state.info.health}/{this.state.info.stun}</Col></Row>
                      <Row><Col>{this.state.info.wultra_scaling}</Col></Row>
                      <Row><Col>{this.state.info.walk_speed.forward}/{this.state.info.walk_speed.back}</Col></Row>
                      <Row>
                        <Col>
                          <Row><Col>{this.state.info.dash.forward.distance} over {this.state.info.dash.forward.duration}f</Col></Row>
                          <Row><Col>{this.state.info.dash.back.distance} over {this.state.info.dash.back.duration}f
                              ({this.state.info.dash.back.invincibility}/{this.state.info.dash.back.airborne}/{this.state.info.dash.back.recovery})</Col></Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row><Col>{this.state.info.jump.height}</Col></Row>
                          <Row><Col>{this.state.info.jump.duration}f ({this.state.info.jump.prejump}/{this.state.info.jump.duration - this.state.info.jump.prejump})</Col></Row>
                          <Row><Col>{this.state.info.jump.distance.forward}/{this.state.info.jump.distance.back}</Col></Row>
                        </Col>
                      </Row>
                      <Row><Col>{this.state.info.wake_up.face_up} up, {this.state.info.wake_up.face_down} down</Col></Row>
                      <Row>
                        <Col>
                          <Row><Col>Level 1</Col><Col>{adv(this.state.info.focus_attack.forward_dash.level_1)}/{adv(this.state.info.focus_attack.back_dash.level_1)}</Col></Row>
                          <Row><Col>Level 2</Col><Col>{adv(this.state.info.focus_attack.forward_dash.level_2)}/{adv(this.state.info.focus_attack.back_dash.level_2)}</Col></Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  </CardText>
                ) : ""}
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card><CardBody>
             <img src={"/portraits/" + this.props.match.params.charName + ".jpg"} width="220px" alt={ this.state.charName }/>
            </CardBody></Card>
          </Col>
        </Row>
        (padding)
        <Nav tabs>
          <NavItem>
            <NavLink
              className={this.state.activeTab === '1' ? "active" : ""}
              onClick={() => { this.toggle('1'); }}
            >
              Move Selection
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === '2' ? "active" : ""}
              onClick={() => { this.toggle('2'); }}
            >
              Move Table
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent fade activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <CharacterMoveSelection matchUrl={match.url} moves={this.state.moves} />
              </Col>
            </Row>
          </TabPane>
          <TabPane style={{"overflow": "scroll", "max-height": "800px"}} tabId="2">
            <Row>
              <Col sm="12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Damage</th>
                      <th scope="col">Stun</th>
                      <th scope="col">Meter Gain</th>
                      <th scope="col">Hit Level</th>
                      <th scope="col">CancelAbility</th>
                      <th scope="col">Startup</th>
                      <th scope="col">Active</th>
                      <th scope="col">Recovery</th>
                      <th scope="col">On Block</th>
                      <th scope="col">On Hit</th>
                      <th scope="col">Hitstun</th>
                      <th scope="col">Blockstun</th>
                      <th scope="col">ExtraDetails</th>
                      <th scope="col">ArmorBreak</th>
                      <th scope="col">Projectile</th>
                      <th scope="col">Throw</th>
                      <th scope="col">OnHitGround</th>
                      <th scope="col">CounterHitGround</th>
                      <th scope="col">OnHitAir</th>
                      <th scope="col">CounterHitAir</th>
                      <th scope="col">Armor</th>
                      <th scope="col">FullInvincible</th>
                      <th scope="col">StrikeInvincible</th>
                      <th scope="col">ProjectileInvincible</th>
                      <th scope="col">ThrowInvincible</th>
                      <th scope="col">Airborne</th>
                      <th scope="col">JuggleInfo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.moves && Object.keys(this.state.moves).map(cat =>
                      this.state.moves[cat].map(move =>
                        <tr>
                          <td>{move.fullName}</td>
                          <td>{move.moveType}</td>
                          <td>{move.Damage}</td>
                          <td>{move.Stun}</td>
                          <td>{move.MeterGain}</td>
                          <td>{move.HitLevel}</td>
                          <td>{move.CancelAbility}</td>
                          <td>{move.startup}</td>
                          <td>{move.active}</td>
                          <td>{move.recovery}</td>
                          <td>{move.AdvOnGuard}</td>
                          <td>{move.AdvOnHit}</td>
                          <td>{move.BlockStun}</td>
                          <td>{move.HitStun}</td>
                          <td>{move.ExtraDetails}</td>
                          <td>{move.ArmorBreak}</td>
                          <td>{move.Projectile}</td>
                          <td>{move.Throw}</td>
                          <td>{move.OnHitGround}</td>
                          <td>{move.CounterHitGround}</td>
                          <td>{move.OnHitAir}</td>
                          <td>{move.CounterHitAir}</td>
                          <td>{move.Armor}</td>
                          <td>{move.FullInvincible}</td>
                          <td>{move.StrikeInvincible}</td>
                          <td>{move.ProjectileInvincible}</td>
                          <td>{move.ThrowInvincible}</td>
                          <td>{move.Airborne}</td>
                          <td>{move.JuggleInfo}</td>
                        </tr>
                        )
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

class CharacterMoveSelection extends Component {
    render(props, state) {
        return (
          <div>
            {props.moves ? (
              <Container>
                <Row>
                  {orderedTypes.filter(value => Object.keys(props.moves).includes(value)).map((category) =>
                    <Col xs="6">
                      <Card>
                        <CardHeader>{getMoveTypeName(category)}</CardHeader>
                        <CardBody>
                          <CardText>
                            {props.moves[category].map(move =>
                                <Button size="sm" style={{margin: "2px"}} href={`${props.matchUrl}/${move.id}`}>{move.fullName} ({move.id})</Button>
                            )}
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Container>
              ) : ""
            }
          </div>
        );
    }
}
