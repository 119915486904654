import { Form, Button, Input, InputGroup, InputGroupAddon, Badge } from 'inferno-bootstrap';
export const MoveSearch = () => (
  <div>
    <InputGroup>
        <Input></Input>
        <InputGroupAddon addonType="append"><Button>Search</Button></InputGroupAddon>
    </InputGroup>

    <p>Search for moves here</p>
    <p>can use the name of a move, or search for properties using certain tags</p>

    <p>list of tags:</p>

        <a href="#"><Badge>char</Badge></a>
        <Badge>punishable_with</Badge>
        <Badge>can_punish</Badge>
        <Badge>on_hit_above</Badge>
        <Badge>on_hit_below</Badge>
        <Badge>on_block_above</Badge>
        <Badge>on_block_below</Badge>
        <Badge>damage_above</Badge>
        <Badge>damage_below</Badge>
        <Badge>stun_above</Badge>
        <Badge>stun_below</Badge>

    <p>examples:</p>

    <li>goshoryu</li>
    <li>s.lp</li>
    <li>char:sagat dragon punch</li>
    <li>char:chun.li on_block_above:2</li>
  </div>
);
